import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../components/common/Button";
import Layout from "../layouts";

const NotFoundPage = () => {
  return (
    <Layout title="404">
      <section className="bg-light dark:bg-dark py-6 sm:py-8 lg:py-12 mt-20">
        <div className="max-w-screen-lg px-4 md:px-8 mx-auto">
          <div className="grid sm:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center items-center sm:items-start md:py-24 lg:py-32">
              <p className="text-text-primary dark:text-text-primary-dark text-sm md:text-base font-semibold uppercase mb-4">
                Error 404
              </p>
              <h1 className="text-text-primary dark:text-text-primary-dark text-2xl md:text-3xl font-bold text-center sm:text-left mb-2">
                Page not found
              </h1>
              <p className="text-gray-500 dark:text-text-primary-dark md:text-lg text-center sm:text-left mb-8">
                The page you're looking for doesn't exist.
              </p>
              <Button as={Link} to="/">
                Go home
              </Button>
            </div>
            <div className="h-80 md:h-auto bg-gray-100 overflow-hidden shadow-lg rounded-lg relative">
              <StaticImage
                src="../static/images/05.jpg"
                alt="Photo by @heydevn"
                className="w-full h-full object-cover object-center absolute inset-0"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
